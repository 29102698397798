const configs = {
  //API: 'https://accred.ru/',
  // API: "https://api.ruvents.com/",
  // API: "http://localhost:11018/",
  API: "https://partner.accred.ruvents.dev/",
  // API: "https://api2.ruvents.dev/",
  //API: 'https://partner.crki.art/api/',
  //IMG: 'https://partner.crki.art/galeryphoto/',
  //IMG: 'https://galeryphoto.ruvents.com/',
  IMG: "http://172.18.0.1:9000/storage",
  //GALERY: 'https://photo-eurasia21.ruvents.com/'
  QContact: "https://qcontact.ru/",
  ContractorsUrl: "https://contractors.accred.ruvents.dev/",
  isTavrida: false,
  ServerName: "accred_ruvents_dev",
  Title: "Тестовый сервер. Партнерка",
};
export default configs;
